<script setup lang="ts">
import { ref } from "vue";
import TocSidebarLayout from "../../_common/components/layout/TocSidebarLayout.vue";
import { Variants } from "../../_common/types/tableOfContents";

definePageMeta({
  layout: "homepage",
});

const tocLinks = [
  { id: "start-with-ai" },
  { id: "ai-screening" },
  { id: "contact-form" },
  { id: "consulting" },
  { id: "services" },
  { id: "workflow" },
  { id: "team" },
];

const contentContainer = ref<null | HTMLElement>(null);
const { activeTableOfContentsItemId } = useTableOfContent(contentContainer);
</script>

<template>
  <TocSidebarLayout
    :active-table-of-contents-item-id="activeTableOfContentsItemId"
    :toc-links="tocLinks"
    page="saxonyaiHome"
    :variant="Variants.SaxonyAi"
  />
  <div
    style="max-width: 100%; overflow-x: hidden"
    ref="contentContainer"
    class="saxony-ai__index"
  >
    <ContentDoc />
  </div>
</template>
